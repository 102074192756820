import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { EnvironmentVars } from '../interfaces/environment-vars';
import { Breadcrumb } from '../components/breadcrumb/interfaces/breadcrumb';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { CompanyIdentityService } from './company-identity-service';

export interface SeoObj {
  title: string,
  metaKey: string | string[],
  metaDesc: string,
  metaImage?: string,
  pageUrl?: string,
  structuredData?: string
}

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  public title: string;
  private metaImageUrl: string;
  private companyDetails: any;

  constructor(
    private meta: Meta,
    private titleService: Title,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    @Inject('environment') private environment: EnvironmentVars,
    private company: CompanyIdentityService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {

    this.title = route.snapshot.data.title;
    this.metaImageUrl = `assets/logo/apnasite/apnasite-logo.png`;

    this.company.companyDetails.subscribe(companyDetails => {
      if (companyDetails) {
        this.companyDetails = companyDetails;
        this.metaImageUrl = companyDetails.logo;
      }
    })

  }

  getCurrentHost() {
    let currentHost = `https://${location.hostname}`

    if (location.hostname == 'localhost') {
      currentHost = currentHost.replace('https', 'http');
      currentHost += ':4200';
    }
    return currentHost;
  }

  async addSeo(title: string, metaKeywords: string | string[], metaDesc: string, metaImage?: string, pageUrl?: string) {
    let metaKey: any = metaKeywords;
    let metaKeyStr: string = metaKey?.join ? metaKey.join(',') : String(metaKey);
    this.titleService.setTitle(title);
    let currentHost = this.getCurrentHost();

    let image = metaImage || this.metaImageUrl;
    image = currentHost + '/' + image.replace(currentHost, '');
    image = image.replace('//api', '/api');

    let seoUrl = pageUrl ? currentHost + pageUrl : currentHost + location.pathname;

    let imageDimentions;

    try {
      imageDimentions = await this.getImageSize(image);
    } catch(e) {
      console.error(e)
    }

    this.meta.addTags([
      { name: 'keywords', content: metaKeyStr },
      { name: 'description', content: metaDesc },

      // Open Graph
      {
        name: 'og:title',
        property: 'og:title',
        content: title
      },
      {
        name: 'og:type',
        property: 'og:type',
        content: 'website'
      },
      {
        name: 'og:description',
        property: 'og:description',
        content: metaDesc
      },
      {
        name: 'og:url',
        property: 'og:url',
        content: seoUrl
      },
      {
        name: 'og:image',
        property: 'og:image',
        content: image
      },
      {
        name: 'og:image:alt',
        property: 'og:image:alt',
        content: title
      },
      {
        name: 'og:image:width',
        property: 'og:image:width',
        content: imageDimentions?.width ? String(imageDimentions?.width) : "200px"
      },
      {
        name: 'og:image:height',
        property: 'og:image:height',
        content: imageDimentions?.height ? String(imageDimentions?.height) : "200px"
      },

      // Twitter
      {
        name: 'twitter:card',
        property: 'twitter:card',
        content: 'summary_large_image'
      },
      {
        name: 'twitter:title',
        property: 'twitter:title',
        content: title
      },
      {
        name: 'twitter:description',
        property: 'twitter:description',
        content: metaDesc
      },
      {
        name: 'twitter:url',
        property: 'twitter:url',
        content: seoUrl
      },
      {
        name: 'twitter:image',
        property: 'twitter:image',
        content: image
      },
    ]);
  }

  async addSeoObj(seo: SeoObj) {
    if (seo && seo.title) {
      await this.addSeo(seo.title, seo.metaKey, seo.metaDesc, seo['metaImg'] || seo.metaImage, seo.pageUrl);
    }
    if (seo?.structuredData) {
      this.addStructuredData(seo.structuredData, seo.pageUrl);
    }
  }

  loadScript(script: string) {
    let scriptEl = this.document.createElement('script');
    scriptEl.type = 'application/ld+json';
    scriptEl.innerHTML = script;
    this.document.head.appendChild(scriptEl);
  }

  addStructuredData(structuredData: any, pageUrl: string) {
    if (structuredData.structuredData) {
      this.loadScript(structuredData.structuredData);
    } else if (structuredData.article) {
      this.articleStructuredData(structuredData, pageUrl);
    } else if (structuredData.articleCategory) {
      this.articleCategoryStructuredData(structuredData, pageUrl);
    }
  }

  articleStructuredData(articleStructuredData: any, url: string) {
    let jsonStr = `
    {
      "@context": "https://schema.org",
      "@type": "NewsArticle",
      "headline": "${articleStructuredData?.article?.heading || articleStructuredData?.article?.title || articleStructuredData?.article?.name}",
      "image": "${this.getCurrentHost()}/${articleStructuredData?.article?.image ? articleStructuredData?.article?.image : this.metaImageUrl}",
      "datePublished": "${articleStructuredData?.article?.publishStartDate || articleStructuredData?.article?.created}",
      "dateModified": "${articleStructuredData?.article?.modified}",
      "url": "${url}",
      "author": [{
          "@type": "Person",
          "name": "${articleStructuredData?.article?.User?.name}",
          "url": "${articleStructuredData?.article?.User?.profileLink || url}"
        }]
    }
    `
    this.loadScript(jsonStr);

  }

  articleCategoryStructuredData(articleStructuredData: any, url: string) {
    let articles = articleStructuredData.articleCategory?.Children?.map((article: any) => {
      return `{
        "@type": "Article",
        "headline": "${article.heading || article.title || article.name}",
        "description": "${article.description}",
        "image": "${this.getCurrentHost()}/${article.image ? article.image : this.metaImageUrl}",
        "url": "/c/${article.id}/${article.name.replace(/[\/ ]/ig, '-')}"
      }`
      
    })

    let jsonStr = `
      {
        "@context": "http://schema.org",
        "@type": "WebPage",
        "url": "${url}",
        "name": "${articleStructuredData.menu?.seo?.title || articleStructuredData.menu?.name || articleStructuredData.articleCategory?.name}",
        "description": "${articleStructuredData.menu?.seo?.description || articleStructuredData.menu?.name || articleStructuredData.articleCategory?.name}",
        "mainContentOfPage": {
          "@type": "WebPageElement",
          "name": "${articleStructuredData.articleCategory?.name}",
          "description": "${articleStructuredData.articleCategory?.description}",
          "image" : "${this.getCurrentHost()}/${articleStructuredData.articleCategory?.image}"
        },
        "hasPart":{
          "@type": "ItemList",
          "name": "Subcategories",
          "itemListElement": [${articles.join()}]
        }
    }
    `
    this.loadScript(jsonStr);

  }

  async getImageSize(url: string) {
  
    if (isPlatformServer(this.platformId)) return;
    const img = new Image();
    img.src = url;
    await img.decode();  
    return {
      height: img.naturalHeight,
      width: img.naturalWidth
    }
  }


  createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    //If no routeConfig is avalailable we are on the root path
    let label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.title : '';
    let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';
    let search = route?.snapshot?.params ? route?.snapshot?.params?.searchTerm : '';

    // If the route is dynamic route such as ':id', remove it
    const lastRoutePart = path.split('/').pop();

    const isDynamicRoute = lastRoutePart.startsWith(':');

    if (isDynamicRoute && !!route.snapshot) {
      const paramName = lastRoutePart.split(':')[1];

      path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
      label = route.snapshot.data.title;
      search = route.snapshot.params.searchTerm;
    }

    //In the routeConfig the complete path is not available,
    //so we rebuild it each time
    const nextUrl = path ? `${url}/${path}` : url;

    const breadcrumb: Breadcrumb = {
      label: label,
      url: nextUrl,
      search: search,
    };
    // Only adding route with non-empty label
    const newBreadcrumbs = breadcrumb.label ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];
    if (route.firstChild) {
      //If we are not on our current path yet,
      //there will be more children to look after, to build our breadcumb

      return this.createBreadcrumbs(route.firstChild, nextUrl, newBreadcrumbs);
    } else {
      if (route.snapshot?.data?.seo) {
        this.addSeoObj(route.snapshot.data.seo);
      }
      return newBreadcrumbs;
    }
  }



}
